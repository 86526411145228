import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../../helpers/visibility'

export default class extends Controller {
  static targets = [
    "addMinutes",
    "addMinutesWrapper",
    "leadTime",
    "leadTimeWrapper",
    "dailyRateWrapper",
    "flatRateWrapper",
    "hourlyRateWrapper",
    "howManyMinutesWrapper",
    "priceMethod",
  ]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    const priceMethod = this.priceMethodTargets.find(input => input.checked).value
    if(priceMethod === "flat_rate") {
      this.flatRateWrapperTarget.classList.remove("d-none")
      this.hourlyRateWrapperTarget.classList.add("d-none")
      this.addMinutesWrapperTarget.classList.remove("d-none")
      this.dailyRateWrapperTarget.classList.add("d-none")
    }
    else if(priceMethod == "hourly") {
      this.flatRateWrapperTarget.classList.add("d-none")
      this.hourlyRateWrapperTarget.classList.remove("d-none")
      this.addMinutesWrapperTarget.classList.add("d-none")
      this.dailyRateWrapperTarget.classList.add("d-none")
    }
    else if(priceMethod == "daily") {
      this.flatRateWrapperTarget.classList.add("d-none")
      this.hourlyRateWrapperTarget.classList.add("d-none")
      this.addMinutesWrapperTarget.classList.add("d-none")
      this.dailyRateWrapperTarget.classList.remove("d-none")
    }

    const addMinutes = this.addMinutesTargets.find(input => input.checked).value === 'true'
    if(addMinutes) {
      this.howManyMinutesWrapperTarget.classList.remove("d-none")
    }
    else {
      this.howManyMinutesWrapperTarget.classList.add("d-none")
    }

    const leadTime = this.leadTimeTargets.find(input => input.checked).value === 'true'
    if(leadTime) {
      showTarget(this.leadTimeWrapperTarget)
    }
    else {
      hideTarget(this.leadTimeWrapperTarget)
    }
  }
}
