import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../../helpers/visibility'

export default class extends Controller {
  static targets = ["payeeType", "payeeValueWrapper", "payeeStaffWrapper"]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    const payeeType = this.payeeTypeTargets.find(r => r.checked).value
    switch(payeeType) {
      case "custom":
        showTarget(this.payeeValueWrapperTarget)
        hideTarget(this.payeeStaffWrapperTarget)
        break;
      case "staff":
      default:
        hideTarget(this.payeeValueWrapperTarget)
        showTarget(this.payeeStaffWrapperTarget)
        break;
    }
  }
}
