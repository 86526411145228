import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["time"]

  connect() {
    this.startTime = new Date()
    this.timer = setInterval(this.updateCountdownTimer, 1000)
    this.updateCountdownTimer()
    this.timeTarget.classList.remove("d-none")
  }

  disconnect() {
    if(this.timer) {
      clearTimer(this.timer)
    }
  }

  updateCountdownTimer = () => {
    const currentTime = new Date()
    const diffSeconds = Math.floor((currentTime.getTime() - this.startTime.getTime()) / 1000)
    const remainingSeconds = parseInt(this.data.get("seconds")) - diffSeconds

    if(remainingSeconds > 0) {
      const displaySeconds = Math.floor(remainingSeconds % 60)
      const displayMinutes = Math.floor(remainingSeconds / 60)

      this.timeTarget.innerText = this.formatTimeLeft(displayMinutes, displaySeconds)
    }
    else {
      this.timeTarget.innerText = '0:00'
    }
  }


  formatTimeLeft(minutes, seconds) {
    const formattedSeconds = seconds.toLocaleString('en', {minimumIntegerDigits: 2})
    return `${minutes}:${formattedSeconds}`
  }
}
