import { Controller } from "stimulus"
import { dynamicContentLoaded } from '../../helpers/dynamic-content'
import { showTarget, hideTarget } from '../../helpers/visibility'
import packageMetaFor from '../../helpers/package-meta'
import normalize from 'json-api-normalizer'

export default class extends Controller {
  static targets = [
    "packageSelector",
    "packageForm",
    "customFieldsWrapper",
    "extraHoursWrapper",
    "extraDaysWrapper",
    "startTimeWrapper",
    "unitsWrapper",
    "unitRangesWrapper"
  ]

  static values = {
    packageMeta: Object
  }

  connect() {
    const normalized = normalize(this.packageMetaValue)
    this.packageMeta = normalized.eventType
    this.packageUnitRangeMeta = normalized.packageUnitRange
    this.updateVisibility()
  }

  updateVisibility() {
    const packageId = this.packageSelectorTarget.value
    const selectedPackageMeta = packageMetaFor({ packageMeta: this.packageMeta, packageUnitRangeMeta: this.packageUnitRangeMeta, packageId })

      if(selectedPackageMeta.dateMode === "date_only") {
        hideTarget(this.startTimeWrapperTarget)
      }
      else {
        showTarget(this.startTimeWrapperTarget)
      }

    if(selectedPackageMeta.enableCustomerCanBookExtraHours) {
      showTarget(this.extraHoursWrapperTarget)
    }
    else {
      hideTarget(this.extraHoursWrapperTarget)
    }

    if(selectedPackageMeta.enableCustomerCanBookExtraDays) {
      showTarget(this.extraDaysWrapperTarget)
    }
    else {
      hideTarget(this.extraDaysWrapperTarget)
    }

    if(packageId === "custom") {
      showTarget(this.customFieldsWrapperTarget)
    }
    else {
      hideTarget(this.customFieldsWrapperTarget)
    }

    if(selectedPackageMeta.enableCustomerCanBookPerUnit) {
      showTarget(this.unitsWrapperTarget)
      this.unitsWrapperTarget.querySelector(".form-label").innerText = selectedPackageMeta.unitsTitle
    }
    else {
      hideTarget(this.unitsWrapperTarget)
    }

    if(selectedPackageMeta.enableCustomerCanBookPerUnitRange) {
      showTarget(this.unitRangesWrapperTarget)
      this.unitRangesWrapperTarget.querySelector(".form-label").innerText = selectedPackageMeta.unitsTitle
      const html = selectedPackageMeta.packageUnitRanges
        .sort((a, b) => a.maxUnits - b.maxUnits)
        .sort((a, b) => a.minUnits - b.minUnits)
        .map(r => `<option value="${ r.id }">${ r.unitRangeLabel }</option>`)
        .join("\n")
      this.unitRangesWrapperTarget.querySelector('select').innerHTML = html
    }
    else {
      hideTarget(this.unitRangesWrapperTarget)
    }
  }
}
