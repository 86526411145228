import { Controller } from "stimulus"
import { dynamicContentLoaded } from '../../helpers/dynamic-content'
import { showTarget, hideTarget } from '../../helpers/visibility'
import packageMetaFor from '../../helpers/package-meta'
import normalize from 'json-api-normalizer'

export default class extends Controller {
  static targets = [
    "newOrExisting",
    "newPackageSelectorWrapper",
    "newPackageSelector",
    "existingDescriptionWrapper",
    "customFieldsWrapper",
    "startTimeWrapper",
    "endTimeWrapper",
    "extraHoursWrapper",
    "extraDaysWrapper",
    "unitsWrapper",
    "unitRangesWrapper",
    "previewImageWrapper"
  ]

  static values = {
    packageMeta: Object,
    existingPackageId: String
  }

  connect() {
    const normalized = normalize(this.packageMetaValue)
    this.packageMeta = normalized.eventType
    this.packageUnitRangeMeta = normalized.packageUnitRange
    this.updateVisibility()
  }

  updateVisibility() {
    const newOrExisting = this.newOrExistingTargets.find(t => t.checked).value
    let selectedPackageMeta

    if(newOrExisting === "existing") {
      selectedPackageMeta = packageMetaFor({ packageMeta: this.packageMeta, packageUnitRangeMeta: this.packageUnitRangeMeta, packageId: this.existingPackageIdValue })

      hideTarget(this.newPackageSelectorWrapperTarget)
      hideTarget(this.customFieldsWrapperTarget)
      showTarget(this.existingDescriptionWrapperTarget)
      showTarget(this.previewImageWrapperTarget)

    }
    else {
      const newPackageId = this.newPackageSelectorTarget.value
      selectedPackageMeta = packageMetaFor({ packageMeta: this.packageMeta, packageUnitRangeMeta: this.packageUnitRangeMeta, packageId: newPackageId })

      showTarget(this.newPackageSelectorWrapperTarget)
      hideTarget(this.existingDescriptionWrapperTarget)
      hideTarget(this.previewImageWrapperTarget)

      if(newPackageId === "custom") {
        showTarget(this.customFieldsWrapperTarget)
      }
      else {
        hideTarget(this.customFieldsWrapperTarget)
      }
    }

    if(selectedPackageMeta.dateMode === "date_only") {
      hideTarget(this.startTimeWrapperTarget)
      hideTarget(this.endTimeWrapperTarget)
    }
    else {
      showTarget(this.startTimeWrapperTarget)
      showTarget(this.endTimeWrapperTarget)
    }

    if(selectedPackageMeta.enableCustomerCanBookExtraHours) {
      showTarget(this.extraHoursWrapperTarget)
    }
    else {
      hideTarget(this.extraHoursWrapperTarget)
    }

    if(selectedPackageMeta.enableCustomerCanBookExtraDays) {
      showTarget(this.extraDaysWrapperTarget)
    }
    else {
      hideTarget(this.extraDaysWrapperTarget)
    }


    if(selectedPackageMeta.enableCustomerCanBookPerUnit) {
      showTarget(this.unitsWrapperTarget)
      this.unitsWrapperTarget.querySelector(".form-label").innerText = selectedPackageMeta.unitsTitle
    }
    else {
      hideTarget(this.unitsWrapperTarget)
    }

    if(selectedPackageMeta.enableCustomerCanBookPerUnitRange) {
      const existingValue = this.unitRangesWrapperTarget.querySelector('select').value
      this.unitRangesWrapperTarget.querySelector(".form-label").innerText = selectedPackageMeta.unitsTitle
      const html = selectedPackageMeta.packageUnitRanges
        .sort((a, b) => a.maxUnits - b.maxUnits)
        .sort((a, b) => a.minUnits - b.minUnits)
        .map(r => `<option value="${ r.id }" ${ existingValue == r.id ? "selected" : ""}>${ r.unitRangeLabel }</option>`)
        .join("\n")
      this.unitRangesWrapperTarget.querySelector('select').innerHTML = html
      showTarget(this.unitRangesWrapperTarget)
    }
    else {
      hideTarget(this.unitRangesWrapperTarget)
    }
  }
}
