// https://gorails.com/episodes/dynamic-nested-forms-with-stimulus-js?autoplay=1
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["links", "template"]

  connect() {
  }

  addAssociation(event) {
    event.preventDefault()

    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML('beforebegin', content)
  }

  removeAssociation(event) {
    event.preventDefault()

    const wrapper = event.target.closest('.nested-fields')
    if(wrapper.dataset.newRecord == "true") {
      wrapper.remove()
    }
    else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = 'none'
    }
  }
}
