import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["dueDate", "dueDateControls"]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    const dueDate = this.dueDateTargets.find(input => input.checked).value === "true"
    if(dueDate) {
      this.dueDateControlsTarget.classList.remove('d-none')
    }
    else {
      this.dueDateControlsTarget.classList.add('d-none')
    }
  }
}
