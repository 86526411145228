import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["allowStaffView", "allowStaffEditContainer", "allowCustomerView", "allowCustomerEditContainer"]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    if(this.allowStaffViewTarget.checked) {
      this.allowStaffEditContainerTarget.classList.remove('d-none')
    }
    else {
      this.allowStaffEditContainerTarget.classList.add('d-none')
    }

    if(this.allowCustomerViewTarget.checked) {
      this.allowCustomerEditContainerTarget.classList.remove('d-none')
    }
    else {
      this.allowCustomerEditContainerTarget.classList.add('d-none')
    }
  }
}
