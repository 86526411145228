import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../../helpers/visibility'

export default class extends Controller {
  static targets = [
    "adjustmentBasedOn", "adjustmentBasedOnFlatRate", "adjustmentBasedOnPercent",
    "basedOnDaysUntilBooking", "daysUntilBooking",
    "basedOnBookDate", "basedOnBookDateDatePicker",
    "basedOnEventDate", "basedOnEventDatePicker",
    "basedOnDayOfWeek", "daysOfWeek",
    "basedOnNumberOfBookings", "numberOfBookings",
    "basedOnVenueLocation", "venueLocationType", "venueLocationSettings", "venueLocationCity", "venueLocationPostalCode", "venueLocationSpecificVenue",
    "basedOnUser", "userSettings",
    "basedOnSubtotal", "subtotal",
    "basedOnSetupLocation", "setupLocation",
    "basedOnEndTime", "endTime"
  ]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    const adjustmentBasedOnValue = this.adjustmentBasedOnTargets.find(input => input.checked).value
    if(adjustmentBasedOnValue === "flat_rate") {
      showTarget(this.adjustmentBasedOnFlatRateTarget)
      hideTarget(this.adjustmentBasedOnPercentTarget)
    }
    else if(adjustmentBasedOnValue === "percent") {
      hideTarget(this.adjustmentBasedOnFlatRateTarget)
      showTarget(this.adjustmentBasedOnPercentTarget)
    }

    const basedOnDaysUntilBooking = this.basedOnDaysUntilBookingTargets.find(input => input.checked).value
    if(basedOnDaysUntilBooking === "true") {
      showTarget(this.daysUntilBookingTargets)
    }
    else {
      hideTarget(this.daysUntilBookingTargets)
    }

    const basedOnBookDateValue = this.basedOnBookDateTargets.find(input => input.checked).value
    if(basedOnBookDateValue === "true") {
      showTarget(this.basedOnBookDateDatePickerTargets)
    }
    else {
      hideTarget(this.basedOnBookDateDatePickerTargets)
    }

    const basedOnEventDateValue = this.basedOnEventDateTargets.find(input => input.checked).value
    if(basedOnEventDateValue === "true") {
      showTarget(this.basedOnEventDatePickerTargets)
    }
    else {
      hideTarget(this.basedOnEventDatePickerTargets)
    }

    const basedOnDayOfWeekValue = this.basedOnDayOfWeekTargets.find(input => input.checked).value
    if(basedOnDayOfWeekValue === "true") {
      showTarget(this.daysOfWeekTarget)
    }
    else {
      hideTarget(this.daysOfWeekTarget)
    }

    const basedOnSubtotalValue = this.basedOnSubtotalTargets.find(input => input.checked).value
    if(basedOnSubtotalValue === "true") {
      showTarget(this.subtotalTargets)
    }
    else {
      hideTarget(this.subtotalTargets)
    }

    // const numberOfBookingsValue = this.basedOnNumberOfBookingsTargets.find(input => input.checked).value
    // if(numberOfBookingsValue === "true") {
    //   showTarget(this.numberOfBookingsTargets)
    // }
    // else {
    //   hideTarget(this.numberOfBookingsTargets)
    // }

    const basedOnVenueLocation = this.basedOnVenueLocationTargets.find(input => input.checked).value
    if(basedOnVenueLocation === "true") {
      showTarget(this.venueLocationSettingsTargets)

      const venueLocationType = this.venueLocationTypeTargets.find(input => input.checked).value
      switch(venueLocationType) {
        case "city":
          showTarget(this.venueLocationCityTarget)
          hideTarget(this.venueLocationPostalCodeTarget)
          hideTarget(this.venueLocationSpecificVenueTarget)
          break;
        case "postal_code":
          hideTarget(this.venueLocationCityTarget)
          showTarget(this.venueLocationPostalCodeTarget)
          hideTarget(this.venueLocationSpecificVenueTarget)
          break;
        case "specific_venue":
          hideTarget(this.venueLocationCityTarget)
          hideTarget(this.venueLocationPostalCodeTarget)
          showTarget(this.venueLocationSpecificVenueTarget)
          break;
      }
    }
    else {
      hideTarget(this.venueLocationSettingsTargets)
    }

    const basedOnUser = this.basedOnUserTargets.find(input => input.checked).value
    if(basedOnUser === "true") {
      showTarget(this.userSettingsTargets)
    }
    else {
      hideTarget(this.userSettingsTargets)
    }

    if(this.hasBasedOnSetupLocationTarget) {
      const basedOnSetupLocationValue = this.basedOnSetupLocationTargets.find(input => input.checked).value
      if(basedOnSetupLocationValue === "true") {
        showTarget(this.setupLocationTargets)
      }
      else {
        hideTarget(this.setupLocationTargets)
      }
    }

    const basedOnEndTime = this.basedOnEndTimeTargets.find(input => input.checked).value
    if(basedOnEndTime === "true") {
      showTarget(this.endTimeTargets)
    }
    else {
      hideTarget(this.endTimeTargets)
    }
  }
}
