import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["radioContainer", "messageContainer"]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    const radioChecked = this.radioContainerTarget.querySelector("input[type=radio]:checked")
    if(radioChecked) {
      switch(radioChecked.value) {
      case "true":
          this.messageContainerTarget.style.display = "none"
          break;
      case "false":
          this.messageContainerTarget.style.display = "block"
          break;
      default:
        throw new Error(`Invalid target ${this.radioContainerTarget.value}`)
      }
    }
  }
}
