import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["position", "modal"]

  connect() {
    this.hidePositionIndexes()

    this.modalTargets.forEach(modal => {
      $(modal).on('show.bs.modal', this.showPositionIndexes)
      $(modal).on('hide.bs.modal', this.hidePositionIndexes)
    })
  }

  hidePositionIndexes = () => {
    this.positionTargets.forEach(position => position.style.display = "none")
    return true
  }

  showPositionIndexes = () => {
    this.positionTargets.forEach(position => position.style.display = "inline")
    return true
  }
}
