import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["variableFeeWrapper", "flatFeeWrapper", "freeDistanceWrapper", "travelFeeTypeWrapper", "customOriginWrapper", "originTypeWrapper"]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    const travelFeeTypeChecked = this.travelFeeTypeWrapperTarget.querySelector("input[type=radio]:checked")
    if(travelFeeTypeChecked) {
      switch(travelFeeTypeChecked.value) {
      case "no_fee":
          this.variableFeeWrapperTarget.style.display = "none"
          this.flatFeeWrapperTarget.style.display = "none"
          this.freeDistanceWrapperTarget.style.display = "none"
          break;
      case "variable":
          this.variableFeeWrapperTarget.style.display = "block"
          this.freeDistanceWrapperTarget.style.display = "block"
          this.flatFeeWrapperTarget.style.display = "none"
          break;
      case "fixed":
          this.variableFeeWrapperTarget.style.display = "none"
          this.flatFeeWrapperTarget.style.display = "block"
          this.freeDistanceWrapperTarget.style.display = "none"
          break;
      default:
        throw new Error(`Invalid target ${this.travelFeeTypeTarget.value}`)
      }
    }

    const originTypeChecked = this.originTypeWrapperTarget.querySelector("input[type=radio]:checked")
    if(originTypeChecked) {
      switch(originTypeChecked.value) {
        case 'business':
          this.customOriginWrapperTarget.style.display = "none"
          break;
        case 'custom':
          this.customOriginWrapperTarget.style.display = "block"
          break;
        default:
          throw new Error(`Invalid target ${this.originTypeChecked.value}`)

      }
    }
  }
}
