import { Controller } from "stimulus"
import Sortable from "sortablejs"
import client from '../../helpers/client'

export default class extends Controller {
  static values = { group: String }

  connect() {
    this.sortable = Sortable.create(this.element, {
      group: this.groupValue || "shared",
      animation: 150,
      onStart: this.start.bind(this),
      onEnd: this.end.bind(this),
      filter: ".no-drag"
    })
  }

  start(event) {
    const wrapperClassTarget = this.wrapperClassTarget(event)
    if(wrapperClassTarget) {
      wrapperClassTarget.classList.add("dragging")
    }
  }

  end(event) {
    const { item, to } = event
    const { id } = item.dataset
    const dropParamAttribute = to.getAttribute("data-drop-params")
    const additionalParams = dropParamAttribute ? JSON.parse(dropParamAttribute) : {}

    const wrapperClassTarget = this.wrapperClassTarget(event)
    if(wrapperClassTarget) {
      wrapperClassTarget.classList.remove("dragging")
    }

    console.log({ to, id, event, newIndex: event.newIndex + 1, additionalParams, dropParamAttribute })

    const url = this.data.get("url").replace(":id", id)
    client.put(url, {
      ...additionalParams,
      position: event.newIndex + 1
    })
      .then(response => {
        console.log(response)
      })
      .catch(e => {
        alert("Unable to update sort order. Please refresh the page and try again.")
        console.log(e)
      })
  }

  wrapperClassTarget(event) {
    if(!event) {
      return null
    }

    const wrapperTargetSelector = event.to.getAttribute("data-drag-wrapper-class-target")
    if(!wrapperTargetSelector) {
      return null
    }

    return document.querySelector(wrapperTargetSelector)
  }
}
