import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["showBackdrops", "allowCustomerToEditBackdrops"]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    if(this.hasShowBackdropsTarget && this.hasAllowCustomerToEditBackdropsTarget) {
      const showBackdrops = this.showBackdropsTargets.find(input => input.checked).value === "true"
      if(showBackdrops) {
        this.allowCustomerToEditBackdropsTarget.classList.remove('d-none')
      }
      else {
        this.allowCustomerToEditBackdropsTarget.classList.add('d-none')
      }
    }
  }
}
