import { Controller } from "stimulus"
import client from '../../helpers/client'

const SPINNER_DELAY = 750

export default class extends Controller {
  static targets = [
    "param",
    "preview"
  ]
  static values = { automatedEventId: String }

  connect() {
    this.renderPreview()
  }

  disconnect() {
    clearTimeout(this.timeout)
  }

  renderPreview() {
    clearTimeout(this.timeout)

    this.timeout = setTimeout(() => this.showSpinner(), SPINNER_DELAY)

    let args = {}

    this.paramTargets.forEach(target => {
      const param = target.getAttribute("data-param")
      const value = target.value
      args[param] = value
    })

    client.post(`/admin/automated_events/${this.automatedEventIdValue}/rendered_preview`, args)
      .then(response => {
        clearTimeout(this.timeout)
        this.previewTarget.innerHTML = response.data
      })
      .catch(e => {
        clearTimeout(this.timeout)
        this.previewTarget.innerHTML = "Please complete all fields to render a preview"
      })
  }

  showSpinner() {
    this.previewTarget.innerHTML = `<div class="text-center"><i class="fa fa-spinner fa-spin"></i> Loading...</div>`
  }
}
