import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["depositBasedOn", "no_deposit", "flatRateWrapper", "percentWrapper"]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    const depositBasedOn = this.depositBasedOnTargets.find(input => input.checked).value
    console.log({ depositBasedOn })

    if(depositBasedOn == "no_deposit" || depositBasedOn == "full_amount") {
      this.flatRateWrapperTarget.classList.add("d-none")
      this.percentWrapperTarget.classList.add("d-none")
    }
    else if(depositBasedOn === "flat_rate") {
      this.flatRateWrapperTarget.classList.remove("d-none")
      this.percentWrapperTarget.classList.add("d-none")
    }
    else if(depositBasedOn == "percent") {
      this.percentWrapperTarget.classList.remove("d-none")
      this.flatRateWrapperTarget.classList.add("d-none")
    }
  }
}
