import { Controller } from "stimulus"
import { dynamicContentLoaded } from '../../helpers/dynamic-content'
import { showTarget, hideTarget } from '../../helpers/visibility'
import packageMetaFor from '../../helpers/package-meta'
import normalize from 'json-api-normalizer'

const CUSTOM_EVENT_NAME = "stimulus-video-play-audio"

export default class extends Controller {
  static targets = [ "quickbooksSyncType", "quickbooksBookingsAndPayments", "quickbooksPaymentsOnly" ]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    if(this.hasQuickbooksSyncTypeTarget) {
      this.updateQuickbooksSyncTypeVisibility()
    }
  }

  updateQuickbooksSyncTypeVisibility() {
    const quickbooksSyncType = this.quickbooksSyncTypeTargets.find(target => target.checked).value
    switch(quickbooksSyncType) {
      case 'bookings_and_payments':
        showTarget(this.quickbooksBookingsAndPaymentsTarget)
        hideTarget(this.quickbooksPaymentsOnlyTarget)
        break
      case 'payments_only':
        showTarget(this.quickbooksPaymentsOnlyTarget)
        hideTarget(this.quickbooksBookingsAndPaymentsTarget)
        break
      case 'manual':
        hideTarget(this.quickbooksBookingsAndPaymentsTarget)
        hideTarget(this.quickbooksPaymentsOnlyTarget)
        break
    }

  }
}
