import { Controller } from "stimulus"
import { hideTarget, showTarget } from '../../helpers/visibility'

export default class extends Controller {
  static targets = ["maxBookings", "allowMultipleBookings", "availableDayOfWeek", "availableEveryDayOfWeek"]

  connect() {
    this.maxBookingsTargets
      .forEach(target => this._setVisibilityBasedOnMaxBookings(target))
    this.allowMultipleBookingsTargets
      .filter(target => target.checked)
      .forEach(target => this._setVisibilityBasedOnMultipleBookings(target))
    this.availableEveryDayOfWeekTargets
      .filter(target => target.checked)
      .forEach(target => this._setVisibilityOfWeekdays(target))
    this.availableDayOfWeekTargets
      .forEach(target => this._setVisibilityOfDayOfWeekTimes(target))
  }

  updateMaxBookings(e) {
    this._setVisibilityBasedOnMaxBookings(e.target)
  }

  updateMultipleBookings(e) {
    this._setVisibilityBasedOnMultipleBookings(e.target)
  }

  updateDayOfWeekVisibility(e) {
    this._setVisibilityOfDayOfWeekTimes(e.target)
  }

  updateEveryDayOfWeek(e) {
    this._setVisibilityOfWeekdays(e.target)
  }

  expandFullSettings(event) {
    event.preventDefault()
    const fullSettings = event.target.closest('.settings-parent').querySelector('.full-settings')
    hideTarget(event.target)
    showTarget(fullSettings)
  }

  _setVisibilityBasedOnMaxBookings(target) {
    const settingsControl = target.closest('.settings-parent').querySelector('.settings-control')

    if(settingsControl && target.value && target.value > 0) {
      showTarget(settingsControl)
    }
    else if(settingsControl) {
      hideTarget(settingsControl)
    }
  }

  _setVisibilityBasedOnMultipleBookings(target) {
    const bookingBufferSettings = target.closest('.settings-parent').querySelector('.booking-buffer-settings')
    if(target.value === 'multiple') {
      showTarget(bookingBufferSettings)
    }
    else {
      hideTarget(bookingBufferSettings)
    }
  }

  _setVisibilityOfWeekdays(target) {
    const daysOfWeek = target.closest('.settings-parent').querySelectorAll('.days-of-week')
    if(target.value === 'false') {
      daysOfWeek.forEach(el => showTarget(el))
    }
    else {
      daysOfWeek.forEach(el => hideTarget(el))
      this.availableDayOfWeekTargets.forEach(el => {
        // Set each of the selects to "yes" I guess?
      })
    }
  }

  _setVisibilityOfDayOfWeekTimes(target) {
    const daysOfWeekControls = target.closest('.days-of-week').querySelectorAll('.day-of-week-times')

    if(target.value === 'partial') {
      daysOfWeekControls.forEach(el => showTarget(el))
    }
    else {
      daysOfWeekControls.forEach(el => hideTarget(el))
    }
  }
}
