import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../../helpers/visibility'

export default class extends Controller {
  static targets = [
    "availableDayOfWeekDropdown",
    "availableEveryDayOfWeekRadio",
    "dayOfWeekOptions",
    "defaultLengthWrapper",
    "defaultLengthHint",
    "defaultLengthLabel",
    "fixedRateWrapper",
    "packageSlots",
    "pricingMethodRadio",
    "timeOfDay",
    "variableRateWrapper"
  ]

  connect() {
    this.updateEveryDayOfWeekRadio()
    this.availableDayOfWeekDropdownTargets
      .forEach(target => this._setVisibilityOfDayOfWeekTimes(target))
    this.updatePricingMethodRadio()
  }

  updateEveryDayOfWeekRadio(e) {
    if(this.hasAvailableEveryDayOfWeekRadioTarget) {
      const value = this.availableEveryDayOfWeekRadioTargets
        .find(radio => radio.checked)
        .value

      switch(value) {
        case "yes":
          hideTarget(this.dayOfWeekOptionsTarget)
          hideTarget(this.timeOfDayTarget)
          break;
        case "yes_certain_hours":
          hideTarget(this.dayOfWeekOptionsTarget)
          showTarget(this.timeOfDayTarget)
          break;
        case "no":
          showTarget(this.dayOfWeekOptionsTarget)
          hideTarget(this.timeOfDayTarget)
          break;
        default:
          throw new Error(`Unexpected value ${value}`)
      }
    }
  }

  updatePricingMethodRadio() {
    const value = this.pricingMethodRadioTargets
      .find(radio => radio.checked)
      .value

    switch(value) {
      case "flat_rate":
        hideTarget(this.variableRateWrapperTargets)
        showTarget(this.fixedRateWrapperTargets)
        if(this.hasDefaultLengthLabelTarget) {
          this.defaultLengthLabelTarget.innerText = "* How many hours is your typical booking?"
        }
        if(this.hasDefaultLengthHintTarget) {
          this.defaultLengthHintTarget.innerText = "Controls how much time we block off on your calendar.  You can override this on the booking"
        }
        break;
      case "variable":
        showTarget(this.variableRateWrapperTargets)
        hideTarget(this.fixedRateWrapperTarget)
        this.defaultLengthLabelTarget.innerText = "* Number of hours included"
        this.defaultLengthHintTarget.innerText = "How many hours are included in your starting rate?"
        break;
      default:
        throw new Error(`Unexpected value ${value}`)
    }
  }


  // updateMaxBookings(e) {
  //   this._setVisibilityBasedOnMaxBookings(e.target)
  // }

  // updateMultipleBookings(e) {
  //   this._setVisibilityBasedOnMultipleBookings(e.target)
  // }

  updateDayOfWeekVisibility(e) {
    this._setVisibilityOfDayOfWeekTimes(e.target)
  }

  expandFullSettings(event) {
    event.preventDefault()
    const fullSettings = event.target.closest('.settings-parent').querySelector('.full-settings')
    hideTarget(event.target)
    showTarget(fullSettings)
  }

  _setVisibilityBasedOnMaxBookings(target) {
    const settingsControl = target.closest('.settings-parent').querySelector('.settings-control')

    if(target.value && target.value > 0) {
      showTarget(settingsControl)
    }
    else {
      hideTarget(settingsControl)
    }
  }

  _setVisibilityBasedOnMultipleBookings(target) {
    const bookingBufferSettings = target.closest('.settings-parent').querySelector('.booking-buffer-settings')
    if(target.value === 'multiple') {
      showTarget(bookingBufferSettings)
    }
    else {
      hideTarget(bookingBufferSettings)
    }
  }

  _setVisibilityOfDayOfWeekTimes(target) {
    const daysOfWeekControls = target.closest('.days-of-week').querySelectorAll('.day-of-week-times')

    if(target.value === 'partial') {
      showTarget(daysOfWeekControls)
    }
    else {
      hideTarget(daysOfWeekControls)
    }
  }
}
