import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "calculatedMinutes",
    "displayMinutes",
    "displayHours"
  ]

  connect() {
    this.setDisplayValuesFromCalculated()
  }

  setDisplayValuesFromCalculated() {
    const calculatedMinutes = this.calculatedMinutesTarget.value
    if(calculatedMinutes) {
      const hours = Math.floor(calculatedMinutes / 60)
      const minutes = calculatedMinutes % 60
      this.displayMinutesTarget.value = minutes
      this.displayHoursTarget.value = hours
    }
  }

  recalculate() {
    // Handle nil values
    if(this.displayHoursTarget.value === '' && this.displayMinutesTarget.value === '') {
      this.calculatedMinutesTarget.value = ''
    }
    // Handle integer values
    else {
      const displayHours = parseInt(this.displayHoursTarget.value, 10) || 0
      const displayMinutes = parseInt(this.displayMinutesTarget.value, 10) || 0
      const calculatedMinutes = (displayHours * 60) + displayMinutes
      this.calculatedMinutesTarget.value = calculatedMinutes
    }
  }

}
