import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../../helpers/visibility'

export default class extends Controller {
  static targets = ["checkbox"]

  toggleAll(e) {
    const checked = e.target.checked
    this.checkboxTargets
      .filter(checkbox => checkbox.checked !== checked)
      .forEach(checkbox => checkbox.click())
  }
}

