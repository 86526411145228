import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../../helpers/visibility'

export default class extends Controller {
  static targets = ["staffAssignmentType", "chosenByCustomerSubsection", "allowOverbooking","overbookingMessageContainer", "assignedStaffSubsection"]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    const staffAssignmentType = this.staffAssignmentTypeTargets.find(input => input.checked).value
    const allowOverbookingChecked = this.allowOverbookingTargets.find(input => input.checked).value === "true"

    switch(staffAssignmentType) {
      case "customer_choice":
        showTarget(this.chosenByCustomerSubsectionTarget)
        hideTarget(this.assignedStaffSubsectionTarget)
        break;
      case "assign_predefined_staff":
        hideTarget(this.chosenByCustomerSubsectionTarget)
        showTarget(this.assignedStaffSubsectionTarget)
        break;
      case "staff_may_claim":
      case "staff_may_request":
      case "none":
        hideTarget(this.chosenByCustomerSubsectionTarget)
        hideTarget(this.assignedStaffSubsectionTarget)
        break;
    }

    if(allowOverbookingChecked) {
      hideTarget(this.overbookingMessageContainerTarget)
    }
    else {
      showTarget(this.overbookingMessageContainerTarget)
    }
  }
}
