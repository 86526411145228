import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "filterPanel",
    "form",
    "showCanceledBtn",
    "showCanceledInput",
    "showConfirmedBtn",
    "showConfirmedInput",
    "showFutureBtn",
    "showFutureInput",
    "showPastBtn",
    "showPastInput",
    "showProposalsBtn",
    "showProposalsInput",
    "showSessionsBtn",
    "showSessionsInput",
    "sortBySelect",
    "sortByInput",
    "toggleFilterButton"
  ]

  connect() {
    this.setupFilterPanel()
  }

  toggleShowPast(e) {
    const currentValue = this.showPastInputTarget.value === "true"
    if(currentValue) {
      this.showPastBtnTarget.classList.remove("active")
      this.showPastInputTarget.value = "false"
    }
    else {
      this.showPastBtnTarget.classList.add("active")
      this.showPastInputTarget.value = "true"
    }
    this.formTarget.submit()
  }

  toggleShowFuture(e) {
    const currentValue = this.showFutureInputTarget.value === "true"
    if(currentValue) {
      this.showFutureBtnTarget.classList.remove("active")
      this.showFutureInputTarget.value = "false"
    }
    else {
      this.showFutureBtnTarget.classList.add("active")
      this.showFutureInputTarget.value = "true"
    }
    this.formTarget.submit()
  }

  toggleShowProposals(e) {
    const currentValue = this.showProposalsInputTarget.value === "true"
    if(currentValue) {
      this.showProposalsBtnTarget.classList.remove("active")
      this.showProposalsInputTarget.value = "false"
    }
    else {
      this.showProposalsBtnTarget.classList.add("active")
      this.showProposalsInputTarget.value = "true"
    }
    this.formTarget.submit()
  }

  toggleShowSessions(e) {
    const currentValue = this.showSessionsInputTarget.value === "true"
    if(currentValue) {
      this.showSessionsBtnTarget.classList.remove("active")
      this.showSessionsInputTarget.value = "false"
    }
    else {
      this.showSessionsBtnTarget.classList.add("active")
      this.showSessionsInputTarget.value = "true"
    }
    this.formTarget.submit()
  }

  toggleShowConfirmed(e) {
    const currentValue = this.showConfirmedInputTarget.value === "true"
    if(currentValue) {
      this.showConfirmedBtnTarget.classList.remove("active")
      this.showConfirmedInputTarget.value = "false"
    }
    else {
      this.showConfirmedBtnTarget.classList.add("active")
      this.showConfirmedInputTarget.value = "true"
    }
    this.formTarget.submit()
  }

  toggleShowCanceled(e) {
    const currentValue = this.showCanceledInputTarget.value === "true"
    if(currentValue) {
      this.showCanceledBtnTarget.classList.remove("active")
      this.showCanceledInputTarget.value = "false"
    }
    else {
      this.showCanceledBtnTarget.classList.add("active")
      this.showCanceledInputTarget.value = "true"
    }
    this.formTarget.submit()
  }

  setupFilterPanel() {
    const width = (window.innerWidth > 0) ? window.innerWidth : screen.width
    if(width <= 480) {
      this.toggleFilterButtonTarget.classList.remove("d-none")
      this.filterPanelTarget.classList.add('d-none')
    }
    else {
      this.toggleFilterButtonTarget.classList.add("d-none")
      this.filterPanelTarget.classList.remove('d-none')
    }
  }

  toggleFilterPanel(e) {
    e.preventDefault()

    if(this.filterPanelTarget.classList.contains('d-none')) {
      this.filterPanelTarget.classList.remove('d-none')
      this.toggleFilterButtonTarget.classList.add('d-none')
    }
    else {
      this.filterPanelTarget.classList.add('d-none')
    }
  }

  updateSortBy(e) {
    this.sortByInputTarget.value = e.target.value
    this.formTarget.submit()
  }
}
