import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../../helpers/visibility'

export default class extends Controller {
  static targets = [ "toggleSwitch", "off", "on" ]
  static values = { default: Boolean }
  
  connect() {
    this.toggleSwitchTarget.checked = this.defaultValue
    this.updateVisibility()
  }

  updateVisibility() {
    if(this.toggleSwitchTarget.checked) {
      showTarget(this.onTargets)
      hideTarget(this.offTargets)
    }
    else {
      showTarget(this.offTargets)
      hideTarget(this.onTargets)
    }
  }
}
