import { Controller } from "stimulus"
import { showTarget, hideTarget } from "../../helpers/visibility"
import timeAgo from "../../helpers/timeAgo"

const delayBeforeShowing = 10


export default class extends Controller {
  static targets = [ "label" ]
  static values = { datetime: String }
  
  connect() {
    const date = new Date(this.datetimeValue)
    const age = Date.now() - date.getTime()

    // Only show if at least delayBeforeShowing seconds old
    if (age > delayBeforeShowing * 1000) {
      this.labelTarget.innerText = `Last updated ${timeAgo(date)}`
      showTarget(this.element)
    } else {
      hideTarget(this.element)
    }
  }
}
