import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../../helpers/visibility'

export default class extends Controller {
  static targets = ["staff", "admin", "customer", "extendedPrivileges", "seeContactInformation", "eventTypes", "packageList", "showStaffDuringCheckout", "digest", "taxPanel", "taxExempt", "taxExemptDetails"]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    const hasCustomerRole = this.customerTarget.checked
    const hasStaffRole = this.staffTarget.checked
    const hasAdminRole = this.adminTarget.checked
    const hasShowStaffDuringCheckout = this.showStaffDuringCheckoutTarget.checked

    if(hasStaffRole && !hasAdminRole) {
      showTarget(this.extendedPrivilegesTarget)
    }
    else {
      hideTarget(this.extendedPrivilegesTarget)
    }

    if(hasStaffRole) {
      showTarget(this.eventTypesTarget)
      showTarget(this.seeContactInformationTarget)
    }
    else {
      hideTarget(this.eventTypesTarget)
      hideTarget(this.seeContactInformationTarget)
    }

    if(hasStaffRole && hasShowStaffDuringCheckout) {
      showTarget(this.packageListTarget)
    }
    else {
      hideTarget(this.packageListTarget)
    }

    if(hasAdminRole) {
      showTarget(this.digestTarget)
    }
    else {
      hideTarget(this.digestTarget)
    }

    if(this.hasTaxPanelTarget) {
      const taxExempt = this.taxExemptTargets.find(taxExempt => taxExempt.checked).value == "true"

      if(hasCustomerRole) {
        showTarget(this.taxPanelTarget)

        if(taxExempt) {
          showTarget(this.taxExemptDetailsTarget)
        }
        else {
          hideTarget(this.taxExemptDetailsTarget)
        }
      }
      else {
        hideTarget(this.taxPanelTarget)
      }
    }
  }
}
