import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../../helpers/visibility'

export default class extends Controller {
  static targets = ["row"]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    this.rowTargets.forEach(row => {
      const value = row.querySelector("select").value
      const timepickerWrappers = row.querySelectorAll(".timepicker-wrapper")

      if(value == "partial") {
        showTarget(timepickerWrappers)
      }
      else {
        hideTarget(timepickerWrappers)
      }
    })
  }
}
