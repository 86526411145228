import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["isLimited", "additionalOptionsContainer"]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    const isLimitedChecked = this.isLimitedTargets.find(input => input.checked).value === "true"

    if(isLimitedChecked) {
      this.additionalOptionsContainerTarget.style.display = "block"
    }
    else {
      this.additionalOptionsContainerTarget.style.display = "none"
    }
  }
}
