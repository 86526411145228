import { Controller } from "stimulus"
import { iframeResizer } from 'iframe-resizer'
import { showTarget, hideTarget } from '../../helpers/visibility'

export default class extends Controller {
  static targets = ["basicMessage", "rawMessage", "showRawMessageLink", "iframe"]

  connect() {
    if(this.hasRawMessageTarget) {
      hideTarget(this.rawMessageTarget)
    }
  }

  showRawMessage(e) {
    e.preventDefault()

    showTarget(this.rawMessageTarget)
    hideTarget(this.basicMessageTarget)
    hideTarget(this.showRawMessageLinkTarget)

    const url = this.iframeTarget.getAttribute("data-message-view-url")
    this.iframeTarget.src = url
    iframeResizer({log: false}, '.checkcherry-autoresize-frame')
  }
}
