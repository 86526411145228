import { Controller } from "stimulus"


const STATE_MAPPINGS = {
  "Ahuachapán Department": "Ahuachapán",
  "Cabañas Department": "Cabañas",
  "Chalatenango Department": "Chalatenango",
  "Cuscatlan Department":"Cuscatlan",
  "La Libertad Department":"La Libertad",
  "La Paz Department":"La Paz",
  "La Unión Department":"La Unión",
  "Morazán Department": "Morazán",
  "San Miguel Department": "San Miguel",
  "San Salvador Department": "San Salvador",
  "San Vicente Department": "San Vicente",
  "Santa Ana Department": "Santa Ana",
  "Sonsonate Department": "Sonsonate",
  "Usulután Department": "Usulutá",

  "Wilayah Persekutuan Kuala Lumpur": "Kuala Lumpur",
  "Wilayah Persekutuan Labuan": "Labuan",
}

export default class extends Controller {
  static targets = [
    "name",
    "street",
    "city",
    "state",
    "postalCode",
    "placeId",
    "lat",
    "lng",
    "photoPreviewContainer",
    "photoPreviewUrl",
    "photoUploadField",
    "attributionInput",
    "attributionDisplay"
  ]

  connect() {
    if(typeof(google) !== "undefined") {
      this.initMap()
    }
  }

  initMap() {
    this.locationType = this.element.getAttribute("data-places-location-type")
    this.countryCode = this.element.getAttribute("data-places-location-country")
    this.fetchPhotos = this.element.getAttribute("data-places-fetch-photo") == "true"

    if(google.maps) {
      if(this.locationType === "venue") {
        this.autocomplete = new google.maps.places.Autocomplete(this.nameTarget)
        this.autocomplete.setFields(['address_components', 'name', 'place_id', 'geometry', 'photos'])
        this.autocomplete.setTypes(["establishment"])
        if(this.countryCode) {
          this.autocomplete.setComponentRestrictions({ country: this.countryCode })
        }

        this.nameTarget.addEventListener('blur', this.placeBlurred)
      }
      else {
        this.autocomplete = new google.maps.places.Autocomplete(this.streetTarget)
        this.autocomplete.setFields(['address_components', 'name', 'place_id', 'geometry'])
        this.autocomplete.setTypes(["address"])
        if(this.countryCode) {
          this.autocomplete.setComponentRestrictions({ country: this.countryCode })
        }

        this.streetTarget.addEventListener('blur', this.placeBlurred)
      }
      this.autocomplete.addListener('place_changed', this.placeChanged)
    }
  }

  placeChanged = () => {
    const place = this.autocomplete.getPlace()

    if(place && place.address_components) {
      const placeId = place.place_id
      const name = place.name
      const postalCode = extractAddressComponent(place, ["postal_code", "plus_code"])
      const city = extractAddressComponent(place, ["locality", "postal_town", "administrative_area_level_2"])
      const state = mapStates(extractAddressComponent(place, ["administrative_area_level_1"]))
      const streetNumber = extractAddressComponent(place, ["street_number"])
      const street = extractAddressComponent(place, ["route"])
      const streetAddress = buildStreetAddress({ name, streetNumber, street })
      const lat = place.geometry.location.lat()
      const lng = place.geometry.location.lng()

      console.log({ place, name, postalCode, city, state, streetNumber, street, streetAddress, lat, lng})

      if(this.hasPlaceIdTarget) {
        this.placeIdTarget.value = placeId
      }
      if(this.locationType === "venue" && this.hasNameTarget) {
        this.nameTarget.value = name
      }
      if(this.hasStreetTarget) {
        this.streetTarget.value = streetAddress
      }
      if(this.hasCityTarget) {
        this.cityTarget.value = city
      }
      if(this.hasStateTarget) {
        this.stateTarget.value = state
      }
      if(this.hasPostalCodeTarget) {
        this.postalCodeTarget.value = postalCode
      }
      if(this.hasLatTarget) {
        this.latTarget.value = lat
      }
      if(this.hasLngTarget) {
        this.lngTarget.value = lng
      }
    }

    if(place && this.fetchPhotos && place.photos && place.photos.length > 0) {
      const photo = place.photos[0]
      const photoUrl = photo.getUrl()
      const attributions = photo.html_attributions.join(",")
      const alreadyChosenFile = this.hasPhotoUploadFieldTarget ? this.photoUploadFieldTarget.value : false

      if(!alreadyChosenFile) {
        if(this.hasPhotoPreviewContainerTarget) {
          const imageTag = `<img src="${photoUrl}" class="img-fluid-center" />`
          this.photoPreviewContainerTarget.innerHTML = imageTag
        }
        if(this.hasPhotoPreviewUrlTarget) {
          this.photoPreviewUrlTarget.value = photoUrl
        }
        if(attributions && this.hasAttributionInputTarget) {
          this.attributionInputTarget.value = attributions
        }
        if(attributions && this.hasAttributionDisplayTarget) {
          this.attributionDisplayTarget.innerHTML = attributions
        }
      }
    }
  }

  placeBlurred = (e) => {
    if(!e.target.value && this.hasPlaceIdTarget) {
      this.placeIdTarget.value = ""
    }
  }

  keydown(event) {
    if(event.key === "Enter") {
      event.preventDefault()
    }
  }
}

const extractAddressComponent = (place, fieldNames) => {
  if(!place || !place.address_components) {
    return ""
  }

  const comp = place.address_components.find(component => hasCommonElements(component.types, fieldNames))
  if(comp && comp.short_name) {
    return comp.short_name
  }
  else if(comp && comp.long_name) {
    return comp.long_name
  }
  else {
    return ""
  }
}

const buildStreetAddress = ({ name, streetNumber, street}) => {
  if(streetNumber && street) {
    return `${streetNumber} ${street}`
  }
  else if(street) {
    return street
  }
  else if(streetNumber) {
    return streetNumber
  }
  else {
    return name
  }
}

const hasCommonElements = (array1, array2) => {
  const result = array1.filter(elem => array2.includes(elem)).length > 0
  // console.log({ array1, array2, result })
  return result
}

const mapStates = (state) => {
  if(STATE_MAPPINGS[state]) {
    return STATE_MAPPINGS[state]
  }
  else {
    return state
  }
}
