import { Controller } from "stimulus"
import { dynamicContentLoaded } from '../../helpers/dynamic-content'
import { showTarget, hideTarget } from '../../helpers/visibility'
import packageMetaFor from '../../helpers/package-meta'
import normalize from 'json-api-normalizer'

const CUSTOM_EVENT_NAME = "stimulus-video-play-audio"

export default class extends Controller {
  static targets = [ "video", "icon" ]

  connect() {
    this.id = crypto.randomUUID()
    this.playEvent = new CustomEvent(CUSTOM_EVENT_NAME, { detail: this.id })

    this.videoTarget.muted = true
    this.videoTarget.play()
    this.iconTarget.classList.add("fa")
    this.iconTarget.classList.add("fa-volume-off")

    this.listener = window.addEventListener(CUSTOM_EVENT_NAME, (event) => {
      // Don't stop ourselves
      if(event.detail != this.id) {
        this.stopAudio()
      }
    })
  }

  disconnect() {
    window.removeEventListener(CUSTOM_EVENT_NAME, this.listener)
  }

  toggleAudio(e) {
    e.preventDefault();

    if(this.videoTarget.muted) {
      this.playAudio()
      window.dispatchEvent(this.playEvent, this.videoTarget);
    }
    else {
      this.stopAudio()
    }
  }

  playAudio() {
    this.videoTarget.muted = false
    this.iconTarget.classList.add("fa-volume-up")
    this.iconTarget.classList.remove("fa-volume-off")
  }

  stopAudio() {
    this.videoTarget.muted = true
    this.iconTarget.classList.add("fa-volume-off")
    this.iconTarget.classList.remove("fa-volume-up")
  }
}
