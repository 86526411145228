import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["type", "imageContainer", "bgContainer", "gradContainer"]

  connect() {
    this.refresh()
  }

  refresh() {
    const type = this.typeTargets.find(input => input.checked).value
    switch(type) {
      case "image":
        this.imageContainerTarget.classList.remove("d-none")
        this.bgContainerTarget.classList.add("d-none")
        this.gradContainerTarget.classList.add("d-none")
        break
      case "solid":
        this.imageContainerTarget.classList.add("d-none")
        this.bgContainerTarget.classList.remove("d-none")
        this.gradContainerTarget.classList.add("d-none")
        break
      case "gradient":
        this.imageContainerTarget.classList.add("d-none")
        this.bgContainerTarget.classList.add("d-none")
        this.gradContainerTarget.classList.remove("d-none")
        break
    }
  }
}
