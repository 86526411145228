import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../../helpers/visibility'

export default class extends Controller {
  static targets = ["recursOn", "dateTypeWrapper", "dayOfMonthWrapper", "monthOfYearWrapper", "payeeType", "payeeValueWrapper"]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    const recursOn = this.recursOnTargets.find(r => r.checked).value
    const customPayeeTarget = this.payeeTypeTargets.find(v => v.value == "custom")
    const staffPayeeWrapperTarget = this.payeeTypeTargets.find(v => v.value == "assigned_staff").closest('fieldset.radio_buttons')

    switch(recursOn) {
      case "each_booking":
        showTarget(this.dateTypeWrapperTarget)
        hideTarget(this.dayOfMonthWrapperTarget)
        hideTarget(this.monthOfYearWrapperTarget)
        showTarget(staffPayeeWrapperTarget)
        break
      case "monthly":
        hideTarget(this.dateTypeWrapperTarget)
        showTarget(this.dayOfMonthWrapperTarget)
        hideTarget(this.monthOfYearWrapperTarget)
        customPayeeTarget.checked = true
        hideTarget(staffPayeeWrapperTarget)
        break
      case "yearly":
        hideTarget(this.dateTypeWrapperTarget)
        showTarget(this.dayOfMonthWrapperTarget)
        showTarget(this.monthOfYearWrapperTarget)
        customPayeeTarget.checked = true
        hideTarget(staffPayeeWrapperTarget)
        break
      default:
        hideTarget(this.dateTypeWrapperTarget)
        hideTarget(this.dayOfMonthWrapperTarget)
        hideTarget(this.monthOfYearWrapperTarget)
        break
    }

    const payeeType = this.payeeTypeTargets.find(r => r.checked).value
    switch(payeeType) {
      case "custom":
        showTarget(this.payeeValueWrapperTarget)
        break;
      default:
        hideTarget(this.payeeValueWrapperTarget)
        break;
    }
  }
}
