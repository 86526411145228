import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["actionType", "existingSlotWrapper", "manualEditWrapper"]

  connect() {
    this.setVisibility()
  }

  setVisibility() {
    const actionType = this.actionTypeTargets.find(input => input.checked)?.value || "choose_from_existing_slots"

    if(actionType === "choose_from_existing_slots") {
      this.existingSlotWrapperTarget.classList.remove("d-none")
      if(this.hasManualEditWrapperTarget) {
        this.manualEditWrapperTarget.classList.add("d-none")
      }
    }
    else if(actionType === "edit_slot_time") {
      this.existingSlotWrapperTarget.classList.add("d-none")
      this.manualEditWrapperTarget.classList.remove("d-none")
    }
  }
}
