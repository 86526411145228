import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../../helpers/visibility'

export default class extends Controller {
  static targets = ["paymentMethodSelector", "discountCodeWrapper"]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    if(this.paymentMethodSelectorTarget.value === "coupon_code") {
      showTarget(this.discountCodeWrapperTarget)
    }
    else {
      hideTarget(this.discountCodeWrapperTarget)
    }
  }
}
