import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["existingCustomerWrapper", "newContactWrapper", "contactType"]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    const contactType = this.contactTypeTargets.find(input => input.checked).value
    if(contactType === "New Customer") {
      this.newContactWrapperTarget.classList.remove("d-none")
      this.existingCustomerWrapperTarget.classList.add("d-none")
    }
    else if(contactType == "Existing Customer") {
      this.newContactWrapperTarget.classList.add("d-none")
      this.existingCustomerWrapperTarget.classList.remove("d-none")
    }
    else {
      this.newContactWrapperTarget.classList.add("d-none")
      this.existingCustomerWrapperTarget.classList.add("d-none")
    }
  }
}
