import { Controller } from "stimulus"
import client from '../../helpers/client'

const SPINNER_DELAY = 750
// const SPINNER_DELAY = 750

export default class extends Controller {
  static targets = ["form", "lengthInMinutes", "startsAtTime", "startsAtDate", "indoorOutdoor", "unit", "packageUnitRangeId"]

  connect() {
    if(this.hasFormTarget) {
      $(this.formTarget).on('dp.change', () => {
        this.updateCart()
      })
    }
  }

  disconnect() {
    clearTimeout(this.timeout)
  }

  updateCart() {
    clearTimeout(this.timeout)

    let args = {}

    if(this.hasLengthInMinutesTarget) {
      args.lengthInMinutes = this.lengthInMinutesTarget.value
    }
    if(this.hasStartsAtTimeTarget) {
      args.startsAtTime = this.startsAtTimeTarget.value
    }
    if(this.hasStartsAtDateTarget) {
      args.startsAtDate = this.startsAtDateTarget.value
    }
    if(this.hasUnitTarget) {
      args.units = this.unitTarget.value
    }
    if(this.hasPackageUnitRangeIdTarget) {
      args.packageUnitRangeId = this.packageUnitRangeIdTarget.value
    }
    if(this.hasIndoorOutdoorTarget) {
      args.indoorOutdoor = this.indoorOutdoorTarget.value
    }


    if(args) {
      // Only show our spinner after we've been waiting a bit. Feels faster to
      // the end user that way
      const cartTarget = document.querySelector("#alternate-cart")
      this.timeout = setTimeout(() => this.showSpinner(), SPINNER_DELAY)

      client.post(`/reservation/cart`, args)
        .then(response => {
          cartTarget.innerHTML = response.data
          clearTimeout(this.timeout)
        })
        .catch(e => {
          cartTarget.innerHTML = "" // Hide the cart if we got an error
          clearTimeout(this.timeout)
        })
    }
  }

  showSpinner() {
    const spinnerTarget = document.querySelector("#alternate-cart .card-body")
    spinnerTarget.innerHTML = `<div class="text-center"><i class="fa fa-spinner fa-spin"></i> Loading...</div>`
  }

}
