import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../../helpers/visibility'
import client from '../../helpers/client'

export default class extends Controller {
  static targets = [
    "form",
    "url",
    "scope",
    "proposalWrapper",
    "leadWrapper",
    "franchiseBlockoutDateWrapper"
  ]

  connect() {
    this.update()
  }

  update() {
    const scope = this.scopeTargets.find(target => target.checked).value
    switch(scope) {
      case "all":
        if(this.hasProposalWrapperTarget) {
          showTarget(this.proposalWrapperTarget)
        }
        if(this.hasLeadWrapperTarget) {
          showTarget(this.leadWrapperTarget)
        }
        if(this.hasFranchiseBlockoutDateWrapperTarget) {
          showTarget(this.franchiseBlockoutDateWrapperTarget)
        }
        break;
      case "my":
        if(this.hasProposalWrapperTarget) {
          this.proposalWrapperTarget.querySelector('input[type=checkbox]').checked = false
          hideTarget(this.proposalWrapperTarget)
        }
        if(this.hasLeadWrapperTarget) {
          this.leadWrapperTarget.querySelector('input[type=checkbox]').checked = false
          hideTarget(this.leadWrapperTarget)
        }
        if(this.hasFranchiseBlockoutDateWrapperTarget) {
          this.franchiseBlockoutDateWrapperTarget.querySelector('input[type=checkbox]').checked = false
          hideTarget(this.franchiseBlockoutDateWrapperTarget)
        }

        break;
    }

    const formData = new FormData(this.formTarget)
    client.post(`/calendar_subscription/build_url`, formData)
      .then(response => {
        // clearTimeout(this.timeout)
        this.urlTarget.value = response.data
      })
      .catch(e => {
        // clearTimeout(this.timeout)
        alert(e.message)
        // this.previewTarget.innerHTML = "Please complete all fields to render a preview"
      })
  }
}
