import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "filterPanel",
    "form",
    "showFutureBtn",
    "showFutureInput",
    "showPastBtn",
    "showPastInput",
    "sortBySelect",
    "sortByInput",
    "toggleFilterButton",
  ]

  connect() {
    this.setupFilterPanel()
  }

  clearPastFutureSelector(e) {
    this.showFutureBtnTarget.classList.add("active")
    this.showFutureInputTarget.value = "true"
    this.showPastBtnTarget.classList.add("active")
    this.showPastInputTarget.value = "true"
  }

  toggleShowPast(e) {
    const currentValue = this.showPastInputTarget.value === "true"
    if(currentValue) {
      this.showPastBtnTarget.classList.remove("active")
      this.showPastInputTarget.value = "false"
    }
    else {
      this.showPastBtnTarget.classList.add("active")
      this.showPastInputTarget.value = "true"
    }
    this.formTarget.submit()
  }

  toggleShowFuture(e) {
    const currentValue = this.showFutureInputTarget.value === "true"
    if(currentValue) {
      this.showFutureBtnTarget.classList.remove("active")
      this.showFutureInputTarget.value = "false"
    }
    else {
      this.showFutureBtnTarget.classList.add("active")
      this.showFutureInputTarget.value = "true"
    }
    this.formTarget.submit()
  }

  setupFilterPanel() {
    const width = (window.innerWidth > 0) ? window.innerWidth : screen.width
    if(width <= 480) {
      this.toggleFilterButtonTarget.classList.remove("d-none")
      this.filterPanelTarget.classList.add('d-none')
    }
    else {
      this.toggleFilterButtonTarget.classList.add("d-none")
      this.filterPanelTarget.classList.remove('d-none')
    }
  }

  toggleFilterPanel(e) {
    e.preventDefault()

    if(this.filterPanelTarget.classList.contains('d-none')) {
      this.filterPanelTarget.classList.remove('d-none')
      this.toggleFilterButtonTarget.classList.add('d-none')
    }
    else {
      this.filterPanelTarget.classList.add('d-none')
    }
  }

  updateSortBy(e) {
    this.sortByInputTarget.value = e.target.value
    this.formTarget.submit()
  }
}
