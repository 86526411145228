import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const sharedContext = require.context("../stimulus/shared_controllers", true, /\.js$/)
const localContext = require.context("../stimulus/franchise_controllers", true, /\.js$/)
application.load(definitionsFromContext(sharedContext))
application.load(definitionsFromContext(localContext))

window.initMap = function(...args) {
  const event = document.createEvent("Events")
  event.initEvent("google-maps-callback", true, true)
  event.args = args
  window.dispatchEvent(event)
}
