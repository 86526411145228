import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "map"]
  connect() {
    if(typeof(google) !== "undefined") {
      this.initMap()
    }
  }

  initMap() {
    const lat = parseFloat(this.mapTarget.getAttribute("data-lat"))
    const lng = parseFloat(this.mapTarget.getAttribute("data-lng"))
    const center = { lat, lng }

    if(google.maps) {
      new google.maps.Map(this.mapTarget, {
        zoom: 14,
        center: center,
        disableDefaultUI: true
      })
    }
  }
}
