import { Controller } from "stimulus"
import copyToClipboard from 'copy-to-clipboard';

export default class extends Controller {
  static targets = ["input", "feedback"]

  connect() {
  }

  disconnect() {
    if(this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  async copy(e) {
    e.preventDefault()

    if(this.timeout) {
      clearTimeout(this.timeout)
    }

    if(e.target.hasAttribute("data-clipboard-value")) {
      const value = e.target.getAttribute("data-clipboard-value")
      copyToClipboard(value)

      if(e.target.hasAttribute("data-clipboard-feedback")) {
        if(!e.target.hasAttribute("data-clipboard-original-content")) {
          e.target.setAttribute("data-clipboard-original-content", e.target.innerHTML)
        }


        const feedbackText = e.target.getAttribute("data-clipboard-feedback") || "Copied!"
        e.target.innerHTML = feedbackText
        this.timeout = setTimeout(() => {
          e.target.innerHTML = e.target.getAttribute("data-clipboard-original-content")
        }, 3000)
      }
    }
    else if(this.hasInputTarget) {
      this.inputTarget.select()
      document.execCommand("copy")

      if(this.hasFeedbackTarget) {
        this.feedbackTarget.innerText = "Copied to clipboard!"
        this.timeout = setTimeout(() => {
          this.feedbackTarget.innerText = ""
        }, 3000)
      }
    }
  }

  showTargetFeedback() {
  }
}
