import { Controller } from "stimulus"
import { hideTarget, showTarget } from "../../helpers/visibility"

export default class extends Controller {
  static targets = ["allowChanges", "howLongContainer"]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    const allowChangesChecked = this.allowChangesTargets.find(input => input.checked).value === "true"

    if(allowChangesChecked) {
      showTarget(this.howLongContainerTarget)
    }
    else {
      hideTarget(this.howLongContainerTarget)
    }
  }
}
