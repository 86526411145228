import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../../helpers/visibility'

export default class extends Controller {
  static targets = ["reason", "searchedSpam", "unmarkedSpam"]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    const reason = this.reasonTarget.value
    switch(reason) {
      case "deliverability_issue":
        showTarget(this.searchedSpamTarget)
        hideTarget(this.unmarkedSpamTarget)
        break;
      case "went_to_spam":
        hideTarget(this.searchedSpamTarget)
        showTarget(this.unmarkedSpamTarget)
        break;
      default:
        hideTarget(this.searchedSpamTarget)
        hideTarget(this.unmarkedSpamTarget)
    }
  }
}
