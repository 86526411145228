import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../../helpers/visibility'

export default class extends Controller {
  static targets = [
    "paymentSelectionMode",
    "defaultModeWrapper",
    "customModeWrapper",
    "autoPay",
    "paymentMethod",
    "calculationMethod",
    "depositAmount",
    "depositPercent",
    "finalPaymentAmount",
    "finalPaymentPercent",
    "scheduledPayment",
  ]

  static values = {
    balance: Number,
    total: Number,
  }

  connect() {
    this.setup()
    this.update()

    $(document).on('cocoon:after-insert cocoon:after-remove', () => this.update())
  }

  disconnect() {
    $(document).off('cocoon:after-insert cocoon:after-remove')
  }

  setup() {
    const calculationMethod = this.calculationMethodTargets.find(target => target.checked).value
    const scheduledPaymentTargets = this.scheduledPaymentTargets
      .filter(target => target.style.display !== "none")

    // Calculate the deposit percent
    const depositAmount = parseFloat(this.depositAmountTarget.value)
    if(!isNaN(depositAmount)) {
      this.depositPercentTarget.value = (depositAmount / this.totalValue * 100).toFixed(0)
    }

    // Calculate the percentages using the payment amounts
    scheduledPaymentTargets.forEach(target => {
      const paymentAmount = parseFloat(target.querySelector(".payment-amount").value)
      if(isNaN(paymentAmount)) {
        return
      }
      const paymentPercent = paymentAmount / this.totalValue * 100
      target.querySelector(".payment-percent").value = paymentPercent.toFixed(0)
    })
  }

  update() {
    this.updatePaymentSelectionModeVisibility()
    this.updateAutoPayVisibility()

    const calculationMethod = this.calculationMethodTargets.find(target => target.checked).value
    const scheduledPaymentTargets = this.scheduledPaymentTargets
      .filter(target => target.style.display !== "none")

    if(calculationMethod === "fixed") {
      this.depositAmountTarget.readOnly = false
      this.depositPercentTarget.readOnly = true
      const depositAmount = parseFloat(this.depositAmountTarget.value)
      if(isNaN(depositAmount)) {
        this.depositPercentTarget.value = ""
      }
      else {
        this.depositPercentTarget.value = (depositAmount / this.totalValue * 100).toFixed(0)
      }
      scheduledPaymentTargets.forEach(target => this.updateScheduledPaymentFixed(target))
    }
    else if(calculationMethod === "percent") {
      this.depositAmountTarget.readOnly = true
      this.depositPercentTarget.readOnly = false
      const depositPercent = parseFloat(this.depositPercentTarget.value)
      if(isNaN(depositPercent)) {
        this.depositAmountTarget.value = ""
      }
      else {
        this.depositAmountTarget.value = (this.totalValue * depositPercent / 100).toFixed(2)
      }
      scheduledPaymentTargets.forEach(target => this.updateScheduledPaymentPercent(target))
    }
    else {
      throw new Error(`Unknown calculation method: ${calculationMethod}`)
    }

    this.updateTotal(scheduledPaymentTargets)
  }

  updateScheduledPaymentFixed(scheduledPaymentTarget) {
    const paymentAmountTarget = scheduledPaymentTarget.querySelector(".payment-amount")
    const paymentPercentTarget = scheduledPaymentTarget.querySelector(".payment-percent")

    paymentAmountTarget.readOnly = false
    paymentPercentTarget.readOnly = true

    const paymentAmount = parseFloat(paymentAmountTarget.value)
    if(isNaN(paymentAmount)) {
      paymentPercentTarget.value = ""
    }
    else {
      const paymentPercent = paymentAmount / this.totalValue * 100
      paymentPercentTarget.value = paymentPercent.toFixed(0)
    }
  }

  updateScheduledPaymentPercent(scheduledPaymentTarget) {
    const paymentAmountTarget = scheduledPaymentTarget.querySelector(".payment-amount")
    const paymentPercentTarget = scheduledPaymentTarget.querySelector(".payment-percent")

    paymentAmountTarget.readOnly = true
    paymentPercentTarget.readOnly = false

    const paymentPercent = parseFloat(paymentPercentTarget.value)
    if(isNaN(paymentPercent)) {
      paymentAmountTarget.value = ""
    }
    else {
      const paymentAmount = this.totalValue * paymentPercent / 100
      paymentAmountTarget.value = paymentAmount.toFixed(2)
    }
  }

  updateTotal(scheduledPaymentTargets) {
    const depositPercent = parseFloat(this.depositPercentTarget.value)
    const scheduledPaymentPercentTotal = scheduledPaymentTargets
      .map(target => parseFloat(target.querySelector(".payment-percent").value))
      .filter(value => !isNaN(value))
      .reduce((sum, value) => sum + value, 0)

    const depositAmount = parseFloat(this.depositAmountTarget.value)
    const scheduledPaymentAmountTotal = scheduledPaymentTargets
      .map(target => parseFloat(target.querySelector(".payment-amount").value))
      .filter(value => !isNaN(value))
      .reduce((sum, value) => sum + value, 0)

    const finalPaymentAmount = (this.totalValue - scheduledPaymentAmountTotal - depositAmount).toFixed(2)
    const finalPercent = (100 - depositPercent - scheduledPaymentPercentTotal).toFixed(0)
    this.finalPaymentAmountTarget.value = finalPaymentAmount
    this.finalPaymentPercentTarget.value = finalPercent
  }

  updatePaymentSelectionModeVisibility() {
    if(this.hasPaymentSelectionModeTarget) {
      const paymentSelectionMode = this.paymentSelectionModeTargets.find(target => target.checked).value 
      if(paymentSelectionMode === "default") {
        showTarget(this.defaultModeWrapperTarget)
        hideTarget(this.customModeWrapperTarget)
      }
      else if(paymentSelectionMode === "custom") {
        hideTarget(this.defaultModeWrapperTarget)
        showTarget(this.customModeWrapperTarget)
      }
      else {
        throw new Error(`Unknown payment selection mode: ${paymentSelectionMode}`)
      }
    }
  }

  updateAutoPayVisibility() {
    if(this.hasAutoPayTarget) {
      const autoPayValue = this.autoPayTargets.find(target => target.checked).value

      if(autoPayValue == "true") {
        showTarget(this.paymentMethodTarget)
      }
      else {
        hideTarget(this.paymentMethodTarget)
      }
    }
  }

}
