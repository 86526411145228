import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../../helpers/visibility'
import chooseMediaLibraryResourcePromise from '../../components/MediaLibraryBrowser/util/chooseMediaLibraryResourcePromise'

export default class extends Controller {
  static targets = ["uploadForm", "buttonGroup", "uploadInput", "cancelWrapper", "statusMessage"]
  static values = { uuid: String }

  connect() {
    hideTarget(this.uploadFormTarget)
    hideTarget(this.cancelWrapperTarget)
    hideTarget(this.statusMessageTarget)
  }

  showUploadForm(e) {
    e.preventDefault()
    showTarget(this.uploadFormTarget)
    hideTarget(this.buttonGroupTarget)
    showTarget(this.cancelWrapperTarget)
  }

  async showMediaLibraryBrowser(e) {
    e.preventDefault()
    try {
      const mediaLibraryResource = await chooseMediaLibraryResourcePromise({ mediaLibraryUuid: this.uuidValue })

      const { uuid, file, url, filename } = mediaLibraryResource.attributes

      const statusMessage = `Selected: ${filename}`
      this.statusMessageTarget.textContent = statusMessage

      this.hiddenInput = document.createElement("input")
      this.hiddenInput.type = "hidden"
      this.hiddenInput.name = this.uploadInputTarget.name
      this.hiddenInput.value = `media_library_resource:${mediaLibraryResource.attributes.uuid}`
      this.uploadInputTarget.insertAdjacentElement("beforebegin", this.hiddenInput)

      hideTarget(this.buttonGroupTarget)
      showTarget(this.statusMessageTarget)
      showTarget(this.cancelWrapperTarget)
    }
    catch(e) {
      console.info(e.message)
      return
    }
  }

  cancel(e) {
    e.preventDefault()

    if(this.hiddenInput) {
      this.hiddenInput.remove()
    }

    this.uploadInputTarget.value = ""
    this.statusMessageTarget.textContent = ""

    hideTarget(this.uploadFormTarget)
    showTarget(this.buttonGroupTarget)
    hideTarget(this.cancelWrapperTarget)
  }
}
