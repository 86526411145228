import { Controller } from "stimulus"
import client from '../../helpers/client'
import { hideTarget, showTarget } from '../../helpers/visibility'

const SPINNER_DELAY = 750

export default class extends Controller {
  static targets = [
    "automatedEventId",
    "subject",
    "emailBody",
    "smsBody",
    "recipientEmails",
    "recipientPhones",
    "delay",
    "delayWrapper",
    "submitButton"
  ]
  static values = { args: Object }

  connect() {
    this.originalSubmitButtonText = this.submitButtonTarget.value
    console.log(this.originalSubmitButtonText)

    this.updateVisibility()
  }

  updateVisibility() {
    const delayValue = this.delayTarget.checked
    if(delayValue) {
      showTarget(this.delayWrapperTarget)
      this.submitButtonTarget.value = "Send Later"
    }
    else {
      hideTarget(this.delayWrapperTarget)
      this.submitButtonTarget.value = this.originalSubmitButtonText
    }
  }

  renderPreview() {
    const automatedEventId = this.automatedEventIdTarget.value

    if(automatedEventId) {
      client.post(`/admin/automated_events/${automatedEventId}/rendered_preview.json`, this.argsValue)
        .then(response => {
          const { subject, messageHtml, smsBody, recipients, sender } = response.data
          if(this.hasSubjectTarget) {
            this.subjectTarget.value = subject
          }

          if(this.hasSmsBodyTarget) {
            this.smsBodyTarget.value = smsBody
          }

          if(this.hasEmailBodyTarget) {
            this.emailBodyTarget.closest('.message_content.quill').querySelector(".ql-editor").innerHTML = messageHtml
          }

          if(this.hasRecipientEmailsTarget && recipients.length > 0) {
            this.recipientEmailsTarget.value = recipients.map(r => r.email).filter(email => email).join(", ")
          }

          if(this.hasRecipientPhonesTarget && recipients.length > 0) {
            this.recipientPhonesTarget.value = recipients.map(r => r.phone).filter(phone => phone).join(", ")
          }
        })
        .catch(e => {
          console.warn(e.message)
          alert("An error occurred loading this template")
        })
    }
    else {
      if(this.hasSubjectTarget) {
        this.subjectTarget.value = ""
      }

      if(this.hasSmsBodyTarget) {
        this.smsBodyTarget.value = ""
      }

      if(this.hasEmailBodyTarget) {
        this.emailBodyTarget.closest('.message_content.quill').querySelector(".ql-editor").innerHTML = ""
      }
    }
  }
}
