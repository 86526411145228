import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../../helpers/visibility'

export default class extends Controller {
  static targets = ["kind", "optionsBox", "collectDuringBooking", "customerAnswerRequired" ]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    if(this.hasKindTarget) {
      const kind = this.kindTarget.value

      switch(kind) {
        case "dropdown":
          showTarget(this.optionsBoxTarget)
          break
        case "radio":
          showTarget(this.optionsBoxTarget)
          break
        case "checkbox_group":
          showTarget(this.optionsBoxTarget)
          break
        case "text":
          hideTarget(this.optionsBoxTarget)
          break
        case "address":
          hideTarget(this.optionsBoxTarget)
          break
        default:
          console.warn(`Unknown kind: ${kind}`)
          break;
      }
    }

    if(this.hasCollectDuringBookingTarget) {
      const collectDuringBooking= this.collectDuringBookingTargets.find(option => option.checked).value

      if(collectDuringBooking === "internal") {
        hideTarget(this.customerAnswerRequiredTarget)
      }
      else {
        showTarget(this.customerAnswerRequiredTarget)
      }
    }
  }
}
