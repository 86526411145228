import { Controller } from "stimulus"
import { showTarget, hideTarget } from '../../helpers/visibility'

export default class extends Controller {
  static targets = ["kind", "file", "link"]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    const kind = this.kindTargets.find(input => input.checked).value

    switch(kind) {
      case "link":
        hideTarget(this.fileTarget)
        showTarget(this.linkTarget)
        break;
      case "document":
        showTarget(this.fileTarget)
        hideTarget(this.linkTarget)
        break;
      default:
        hideTarget(this.fileTarget)
        hideTarget(this.linkTarget)
    }
  }
}
