const DEFAULT_PACKAGE_META = {
  enableCustomerCanBookExtraHours: false,
  enableCustomerCanBookExtraDays: false,
  enableCustomerCanBookPerUnit: false,
  enableCustomerCanBookPerUnitRange: false,
  hasBackdrops: true,
  dateMode: "date_time"
}

function packageMetaFor({ packageMeta, packageUnitRangeMeta, packageId }) {
  if(packageMeta && packageMeta[packageId]) {
    const meta = packageMeta[packageId].attributes

    if(packageUnitRangeMeta) {
      meta.packageUnitRanges = Object.values(packageUnitRangeMeta)
        .map(r => r.attributes)
        .filter(r => r.eventTypeId == packageId )
    }

    return meta
  }
  else {
    return DEFAULT_PACKAGE_META
  }
}

export default packageMetaFor
