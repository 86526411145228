import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["fieldVisibility", "wrapper", "submitAction", "redirectUrl", "message"]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    this.fieldVisibilityTargets.forEach(target => {
      const related = target.getAttribute('data-related')
      if(target.value) {
        // Show
        this.wrapperTarget.querySelectorAll(related).forEach(el => el.style.display = "block")
      }
      else {
        // Hide
        this.wrapperTarget.querySelectorAll(related).forEach(el => el.style.display = "none")
      }
    })

    switch(this.submitActionTarget.value) {
      case "redirect_to_url":
        this.messageTarget.style.display = "none"
        this.redirectUrlTarget.style.display = "block"
        break
      case "redirect_to_booking":
        this.messageTarget.style.display = "none"
        this.redirectUrlTarget.style.display = "none"
        break
      default:
        this.messageTarget.style.display = "block"
        this.redirectUrlTarget.style.display = "none"
    }
  }
}
